import React from 'react';
import sauravImage from "../../images/saurav.png";
import { StarIcon } from '@heroicons/react/20/solid';

const testimonial = {
  id: 1,
  stars: 5,
  quote: "As a recent graduate new to the UK job market, I decided to use UKWorkx's auto-apply service. Their team was incredibly helpful in preparing me for interviews and navigating the local job landscape. Thanks to their guidance, which I started using before graduation, I successfully secured a position as a Management Accountant at the BBC. I highly recommend their services for anyone in a similar situation.",
  author: 'Saurav Banojia',
  jobTitle: 'Management Accountant @BBC London',
  avatarUrl: sauravImage,
  linkedIn: 'https://www.linkedin.com/in/saurav-banojia-107b1b198/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Our client, a Masters student at King's College London, came to the UK in September 2022 to pursue his degree. As an international student with limited knowledge of the UK job market, he recognized the need for professional guidance to secure a position in his field upon graduation."
  },
  {
    title: "Challenges",
    content: [
      "Lack of familiarity with the UK job market",
      "Need to secure a position before graduation",
      "Competition from local candidates with established networks",
      "Limited time to dedicate to job searching while completing his studies"
    ]
  },
  {
    title: "Solution",
    content: [
      "ATS-optimized CV and personalized guidance",
      "Auto-apply feature to suitable positions",
      "Job-specific test preparation",
      "Twice-weekly 40-minute meetings for ongoing support"
    ],
    intro: "In April 2023, the client chose our UKworkx Elite plan, our most comprehensive package designed for those seeking a fast-tracked path to their ideal career. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Optimize his CV for ATS systems, achieving a high score to ensure it reached decision-makers",
      "Provide industry insights and effective job application strategies",
      "Utilize our auto-apply feature to target suitable positions at top companies",
      "Conduct intensive mock interviews tailored to management accountant roles",
      "Prepare him for job-specific tests common in the finance sector",
      "Offer continuous support and guidance through twice-weekly meetings"
    ],
    intro: "Our team worked closely with the client to:"
  },
  {
    title: "Results",
    content: [
      "The client secured a Management Accountant position at BBC London in July 2023",
      "He successfully transitioned from student to professional, starting his role in September 2023",
      "The entire process, from engaging our services to job offer, took just three months",
      "The client achieved his goal of securing a position before completing his Masters degree"
    ],
    intro: "Thanks to our comprehensive approach and the client's dedication:"
  }
];

function CaseStudySaurav() {
  return (
    <div className="pb-12 pt-10 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-black text-center mb-8">
        Client Case Study: From International Student to BBC Management Accountant
      </h1>

      {caseStudyContent.map((section, index) => (
        <section key={index} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
          {section.intro && <p className="mb-2">{section.intro}</p>}
          {Array.isArray(section.content) ? (
            <ul className="list-disc pl-5 space-y-2">
              {section.content.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          ) : (
            <p>{section.content}</p>
          )}
        </section>
      ))}

      <section className="mt-12">
        <h2 className="text-2xl font-semibold mb-4 text-center">Client Testimonial</h2>
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <StarIcon key={index} className="h-6 w-6 text-yellow-400" aria-hidden="true" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={testimonial.avatarUrl}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              <a 
                href={testimonial.linkedIn} 
                className="text-blue-600 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                View LinkedIn Profile
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CaseStudySaurav;