import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p13.jpeg"


const testimonial = {
  id: 1,
  stars: 5,
  quote: "After completing my MSc in Marketing at Brunel University, I was eager to apply my skills in the dynamic UK market. However, finding a sponsored position in marketing seemed like a daunting task. UKWorkx's expertise was a game-changer. They helped me showcase my digital marketing skills, connect with innovative companies, and navigate the intricate visa process. Thanks to their guidance, I secured an exciting role at a fast-growing tech startup. UKWorkx truly helped me turn my marketing aspirations into reality!",
  author: 'Kriteshini',
  jobTitle: 'Digital Marketing Specialist, London',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/kriteshini-rajendran-marketing/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Kriteshini, an Indian national, completed her MSc in Marketing at Brunel University in August 2023. With a passion for digital marketing and a keen understanding of emerging trends, she aimed to secure a position in the UK's competitive marketing industry while navigating the complex visa sponsorship process."
  },
  {
    title: "Challenges",
    content: [
      "Highly competitive field with limited sponsored positions for international graduates",
      "Need for a role offering Skilled Worker visa sponsorship in the marketing sector",
      "Demonstrating practical skills alongside academic knowledge",
      "Limited professional network in the UK marketing community",
      "Adapting to UK-specific marketing regulations and practices",
      "Standing out in a crowded job market with many local graduates"
    ]
  },
  {
    title: "Solution",
    content: [
      "Comprehensive review and optimization of CV and digital marketing portfolio",
      "Targeted search for graduate positions in digital and content marketing",
      "Interview preparation focusing on practical marketing scenarios and case studies",
      "Networking strategies within the UK marketing and tech startup community",
      "Guidance on visa sponsorship for marketing roles and UK advertising standards",
      "Support in creating a personal brand to showcase marketing expertise"
    ],
    intro: "In June 2023, Kriteshini enrolled in our UKWorkx Elite Plan, tailored for international graduates. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a thorough review of Kriteshini's CV, highlighting key digital marketing projects and analytical skills",
      "Identified UK-based companies, particularly in the tech sector, with a history of sponsoring marketing roles",
      "Provided intensive preparation for marketing strategy presentations and analytics-based interviews",
      "Arranged introductions to alumni and professionals in the UK digital marketing sector",
      "Offered guidance on UK data protection regulations and digital marketing best practices",
      "Helped create a personal website and LinkedIn profile to showcase Kriteshini's marketing campaigns and results"
    ],
    intro: "Our team collaborated closely with Kriteshini to:"
  },
  {
    title: "Results",
    content: [
      "Kriteshini secured a Digital Marketing Specialist position at TechGrowth Solutions in London in October 2023",
      "The role came with full Skilled Worker visa sponsorship",
      "She received interest from multiple companies, including established firms and innovative startups",
      "Successfully transitioned from her student visa to a Skilled Worker visa",
      "Started her UK marketing career within 2 months of graduation",
      "Gained a position that allows her to work on cutting-edge digital marketing strategies for tech products"
    ],
    intro: "Through our specialized approach and Kriteshini's dedication:"
  }
];

function CaseStudyKriteshini() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From Marketing Graduate to Sponsored Digital Marketing Specialist in London
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyKriteshini;