import React from 'react'
import Pricing from '../features/pricing/Pricing'
import ContactForm from "../features/contactform/ContactForm"
import { useState } from 'react';
import Navbar from '../features/navbar/Navbar'
import TopBanner from "../features/banners/TopBanner"
import Footer from "../features/footer/Footer"

function PricingPage() {
    const [showContactForm, setShowContactForm] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleContactFormOpen = (action) => {
    setSelectedAction(action);
    setShowContactForm(true);
  };

  const handleContactFormClose = () => {
    setShowContactForm(false);
    setSelectedAction('');
  };
  return (
    <>
    <TopBanner onContactFormOpen={handleContactFormOpen} />
    <Navbar />
        <Pricing onContactFormOpen={handleContactFormOpen} />
        {showContactForm && (
        <ContactForm
          onClose={handleContactFormClose}
          selectedAction={selectedAction}
        />
      )}
      <Footer/>
    </>
  )
}

export default PricingPage