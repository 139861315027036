import React from 'react';
import "./custom.css";

function RadialProgress({ percentage }) {
  let color;

  if (percentage <= 50) {
    color = 'text-red-500';
  } else if (percentage <= 75) {
    color = 'text-yellow-500';
  } else {
    color = 'text-green-500';
  }

  return (
    <div className="relative w-40 h-40">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-gray-200 stroke-current"
          strokeWidth="10"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
        ></circle>

        <circle
          className={`progress-ring__circle stroke-current ${color}`}
          strokeWidth="10"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          strokeDasharray="251.2"
          strokeDashoffset={`calc(251.2 - (251.2 * ${percentage}) / 100)`}
        ></circle>

        <text
          x="50"
          y="50"
          fontFamily="Verdana"
          fontSize="12"
          textAnchor="middle"
          alignmentBaseline="middle"
          className={color}
        >
          {percentage}%
        </text>
      </svg>
    </div>
  );
}

export default RadialProgress;