// AtsReport.js

import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import RadialProgress from "./RadialProgress.js";
import {
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import WordCloud from "./WordCloud";
import Testimonials from "../testimonials/Testimonials.js";
import { StepChart } from "../stepchart/StepChart.js";

const stripePromise = loadStripe(
  "pk_live_51PTOWUH5KSl7XetSDdFoXBg9E6aChQ2CZBAfOWvKvKzWK22W5P4bXTBeGBxIeILQzDLj54jn9B5Qsl8ay3K6wmys00vLIxrKlS"
);

export default function AtsReport({ onContactFormOpen }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const score = parseFloat(searchParams.get("score"));
  const [paymentStatus, setPaymentStatus] = useState(null);

  const detailedResponse = JSON.parse(searchParams.get("detailedResponse"));

  const servicePackage = "Full PDF Report £9.99";

  const handlePayment = async () => {
    const stripe = await stripePromise;
    const response = await fetch("/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, servicePackage }), // Send the name in the request body
    });

    const session = await response.json();
    const result = await stripe.redirectToCheckout({ sessionId: session.id });

    if (result.error) {
      setPaymentStatus("failed");
    }
  };

  // Check if the required data is present in the URL query parameters
  if (!name || !detailedResponse) {
    // Redirect the user to the ATS checker results page
    return <Navigate to="/atschecker" replace />;
  }

  const getScoreStatus = () => {
    if (!score) {
      return {
        text: "N/A",
        color: "bg-gray-50 text-gray-700 ring-gray-600/20",
      };
    } else if (score < 2.2) {
      return { text: "Low", color: "bg-red-50 text-red-700 ring-red-600/20" };
    } else if (score < 3.6) {
      return {
        text: "Average",
        color: "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
      };
    } else {
      return {
        text: "High",
        color: "bg-green-50 text-green-700 ring-green-600/20",
      };
    }
  };

  const scoreStatus = getScoreStatus();

  return (
    <>
      <div className="z-50 pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 rounded-lg sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 w-full sm:w-auto">
          <p className="text-sm leading-6 text-white">
            <button onClick={() => onContactFormOpen("Book a Free Call")}>
              <strong className="font-semibold">UKworkx 2024</strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
              Book a free 15 minutes call now with one of our consultants.&nbsp;
              <span aria-hidden="true"></span>
            </button>
          </p>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <h2 className="text-2xl text-center font-bold leading-8 tracking-tight text-gray-900">
            Detailed Resume Analysis Report For <span className="">{name}</span>
          </h2>
          <p className="mt-3 text-sm text-center font-semibold tracking-tight text-gray-900">
            Hey {name}, Your free detailed resume analysis and ATS report is
            ready to view. Please scroll down below.{" "}
          </p>
          <div className="mt-5 rounded-lg shadow-sm ring-1 ring-gray-900/5">
            <dl className="flex flex-wrap pb-10">
              <div className="flex-auto pl-6 pt-6">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  ATS Score
                </dt>
                <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                  {score ? score.toFixed(1) : "N/A"}
                </dd>
              </div>
              <div className="flex-none self-end px-6 pt-4">
                <dt className="sr-only">Status</dt>
                <dd
                  className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${scoreStatus.color}`}
                >
                  {scoreStatus.text}
                </dd>
              </div>
            </dl>
          </div>
          <p className="text-center italic text-[10px] sm:text-xs mt-2">
            With a 93% client satisfaction rate, we excel in securing visa
            sponsorships, empowering our clients to achieve their global career
            dreams.
          </p>

          <h1 className="font-bold text-xl text-center mt-5">
            Your Detailed Report
          </h1>
          <div className="flex justify-center mt-5">
            <RadialProgress percentage={Math.ceil(score * 20)} />
          </div>
          <h1 className="text-center font-extrabold my-4 text-lg">
            {name}'s ATS Score
          </h1>
          <h1 className="text-center font-medium">
            A high ATS score is crucial for international students seeking UK
            employment. It ensures your resume passes the initial screening by
            ATS software, widely used by UK employers. Optimizing your resume to
            meet UK-specific formats and terminology increases your chances of
            securing interviews and job offers in a competitive market where
            visa sponsorship is an additional challenge.
          </h1>
          <h1 className="font-bold text-2xl text-center mt-10">
            Possible reasons could be:
          </h1>
          <dl className="mt-5 space-y-8 divide-y divide-gray-900/10">
            {detailedResponse
              .filter(
                (result) =>
                  result.section && result.observation && result.recommendation
              )
              .map((result, index) => (
                <div
                  key={index}
                  className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
                >
                  <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                    <ExclamationTriangleIcon className="w-8 fill-warning" />
                    {result.section}
                  </dt>
                  <dd className="mt-4 lg:col-span-7 lg:mt-0">
                    <p className="text-base leading-7 text-gray-600">
                      {result.observation}
                    </p>
                    <p className="text-base leading-7 text-gray-600">
                      {result.recommendation}
                    </p>
                  </dd>
                </div>
              ))}

            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={Math.min(
                    100,
                    (detailedResponse
                      .find((item) => item.goodkeywords)
                      ?.goodkeywords.split(", ").length || 0) * 10
                  )}
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Keywords that will impress recruiters in {name}'s resume
            </h1>
            <div>
              {(() => {
                const keywords =
                  detailedResponse
                    .find((item) => item.goodkeywords)
                    ?.goodkeywords.split(", ") || [];
                const keywordCount = keywords.length;

                let message = "";
                if (keywordCount < 8) {
                  message = "Consider adding more keywords to stand out.";
                } else if (keywordCount <= 10) {
                  message = "Your resume keywords will stand out!";
                } else {
                  message =
                    "There are too many buzzwords in your resume. Consider removing some of them.";
                }

                return (
                  <p className="text-center underline font-medium">{message}</p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium">
              Highlighting relevant keywords and buzzwords in your resume is
              essential for international students targeting UK jobs.
              Strategically incorporating these terms demonstrates your
              knowledge, skills, and experience to potential employers.
              Optimizing your resume with the right keywords sets you apart in a
              competitive job market and shows employers you are the ideal
              candidate worthy of visa sponsorship.
            </h1>
            <WordCloud
              keywords={
                detailedResponse
                  .find((item) => item.goodkeywords)
                  ?.goodkeywords.split(", ") || []
              }
            />
            <div className="flex justify-center mt-5">
              <h1 className="text-6xl font-bold">
                {Number(
                  detailedResponse.find((item) => item.wordcount)?.wordcount ||
                    0
                )}
                /<span className="text-lg"> Words</span>
              </h1>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Wordcount Analysis for {name}'s Resume
            </h1>
            <div>
              {(() => {
                const wordCount = Number(
                  detailedResponse.find((item) => item.wordcount)?.wordcount ||
                    0
                );

                let message = "";
                if (wordCount < 300) {
                  message =
                    "Your resume's wordcount is too low. Consider adding more relevant information.";
                } else if (wordCount <= 600) {
                  message =
                    "Your resume's wordcount is within the optimal range. It provides sufficient information without being too lengthy.";
                } else {
                  message =
                    "Your resume's wordcount is higher than the optimal range. Consider removing less relevant information to make it more concise.";
                }

                return (
                  <p className="text-center underline font-medium">{message}</p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium">
              As an international student applying for UK jobs, it's important
              to strike a balance in your resume's wordcount. A resume that is
              too short may not provide enough information to showcase your
              skills and experience, while an overly lengthy resume may lose the
              employer's interest. Aim for a wordcount between 300 and 600 words
              to effectively highlight your qualifications without overwhelming
              the reader. This range allows you to demonstrate your suitability
              for the role while respecting the employer's time.
            </h1>
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={Math.min(
                    100,
                    (detailedResponse
                      .find((item) => item.goodbuzzwords)
                      ?.goodbuzzwords.split(", ").length || 0) * 10
                  )}
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Buzzwords that will impress recruiters in {name}'s resume
            </h1>
            <div>
              {(() => {
                const keywords =
                  detailedResponse
                    .find((item) => item.goodbuzzwords)
                    ?.goodbuzzwords.split(", ") || [];
                const keywordCount = keywords.length;

                let message = "";
                if (keywordCount < 8) {
                  message = "Consider adding more buzzwords to stand out.";
                } else if (keywordCount <= 10) {
                  message = "Your resume has buzzwords that will stand out!";
                } else {
                  message =
                    "There are too many buzzwords in your resume. Consider removing some of them.";
                }

                return (
                  <p className="text-center underline font-medium">{message}</p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium">
              Incorporating buzzwords in your resume is crucial for ATS
              optimization and capturing recruiters' attention. However,
              moderation is key. Aim for 8-10 relevant buzzwords or
              industry-specific terms throughout your resume. These should be
              strategically placed in your skills section, job descriptions, and
              professional summary. Choose words that align with the job
              description and your industry, but avoid overuse or forced
              inclusion. The right buzzwords can demonstrate your familiarity
              with the field and help your resume pass ATS filters. However,
              remember that your experience and achievements should speak louder
              than trendy terminology. Balance is essential to create a resume
              that's both ATS-friendly and compelling to human readers.
            </h1>
            <WordCloud
              keywords={
                detailedResponse
                  .find((item) => item.goodbuzzwords)
                  ?.goodbuzzwords.split(", ") || []
              }
            />
            <div className="flex justify-center mt-5">
              <h1 className="text-6xl font-bold">
                {Number(
                  detailedResponse.find((item) => item.totalbulletpoints)
                    ?.totalbulletpoints || 0
                )}
                /<span className="text-lg"> Bullet Points</span>
              </h1>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Total bullet points in {name}'s Resume
            </h1>
            <div>
              {(() => {
                const wordCount = Number(
                  detailedResponse.find((item) => item.totalbulletpoints)
                    ?.totalbulletpoints || 0
                );

                let message = "";
                if (wordCount < 20) {
                  message =
                    "Your resume's bullet points count is too low. Consider adding more relevant information.";
                } else if (wordCount <= 30) {
                  message =
                    "Your resume's bullet points count is within the optimal range. It provides sufficient information without being too lengthy.";
                } else {
                  message =
                    "Your resume's bullet points count is higher than the optimal range. Consider removing less relevant information to make it more concise.";
                }

                return (
                  <p className="text-center underline font-medium">{message}</p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium">
              In resume writing, the number of bullet points can significantly
              impact effectiveness. A total of 20-30 bullet points across your
              entire resume is generally optimal. This range allows you to
              highlight key achievements and skills without overwhelming
              readers. Distribute these points strategically, emphasizing recent
              and relevant experiences. Each bullet should be concise,
              impactful, and where possible, quantifiable. This approach
              balances detail with readability, appealing to both ATS systems
              and human recruiters, and increasing your chances of landing an
              interview.
            </h1>
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={Math.max(
                    0,
                    100 -
                      (detailedResponse
                        .find((item) => item.wordsyoushouldremove)
                        ?.wordsyoushouldremove.split(", ").length || 0) *
                        10
                  )}
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Words To Remove from {name}'s Resume
            </h1>
            <div>
              {(() => {
                const wordsToRemove =
                  detailedResponse
                    .find((item) => item.wordsyoushouldremove)
                    ?.wordsyoushouldremove.split(", ") || [];
                const wordCount = wordsToRemove.length;

                let message = "";
                if (wordCount === 0) {
                  message =
                    "Great! Your resume doesn't contain any words that should be removed.";
                } else if (wordCount === 1) {
                  if (wordsToRemove[0] === "0" || wordsToRemove[0] === 0) {
                    message =
                      "Great! Your resume doesn't contain any words that should be removed.";
                  } else {
                    message =
                      "Your resume contains 1 word that should be removed. Consider removing it to improve your resume.";
                  }
                } else {
                  message = `Your resume contains ${wordCount} words that should be removed. Consider removing them to improve your resume.`;
                }

                return (
                  <p className="text-center underline font-medium">{message}</p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium">
              As an international student applying for UK jobs, it's important
              to avoid using certain words or phrases in your resume that may be
              considered unprofessional or cliché. These words can make your
              resume sound generic and fail to highlight your unique skills and
              experiences. By removing these words and replacing them with more
              specific and relevant terms, you can strengthen your resume and
              increase your chances of impressing potential employers.
            </h1>
            <WordCloud
              keywords={
                detailedResponse
                  .find((item) => item.wordsyoushouldremove)
                  ?.wordsyoushouldremove.split(", ") || []
              }
            />
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={Math.max(
                    0,
                    100 -
                      (detailedResponse
                        .find((item) => item.spellingmistakes)
                        ?.spellingmistakes.split(", ").length || 0) *
                        10
                  )}
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Spelling Mistakes in {name}'s Resume
            </h1>
            <div>
              {(() => {
                const wordsToRemove =
                  detailedResponse
                    .find((item) => item.spellingmistakes)
                    ?.spellingmistakes.split(", ") || [];
                const wordCount = wordsToRemove.length;

                let message = "";
                if (wordCount === 0) {
                  message =
                    "Great! Your resume doesn't contain any spelling mistakes that should be removed.";
                } else if (wordCount === 1) {
                  if (wordsToRemove[0] === "0" || wordsToRemove[0] === 0) {
                    message =
                      "Great! Your resume doesn't contain any spelling mistakes that should be removed.";
                  } else {
                    message =
                      "Your resume contains 1 spelling mistake that should be removed. Consider removing it to improve your resume.";
                  }
                } else {
                  message = `Your resume contains ${wordCount} spelling mistakes that should be removed. Consider removing them to improve your resume.`;
                }

                return (
                  <p className="text-center underline font-medium">{message}</p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium">
              Spelling mistakes in a resume can be detrimental to your job
              prospects. Even a single error can create a negative impression,
              suggesting carelessness or lack of attention to detail. These
              mistakes can also confuse ATS systems, potentially lowering your
              resume's score or causing it to be filtered out before reaching
              human reviewers. For international students, the risk is higher
              due to potential language barriers or unfamiliarity with UK
              spelling conventions. It's crucial to proofread your resume
              multiple times, use spell-check tools, and consider having a
              native English speaker review it. Remember, a flawless resume
              demonstrates professionalism and respect for the employer's time,
              significantly boosting your chances of securing an interview.
            </h1>
            <WordCloud
              keywords={
                detailedResponse
                  .find((item) => item.spellingmistakes)
                  ?.spellingmistakes.split(", ") || []
              }
            />
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={Math.max(
                    0,
                    100 -
                      (detailedResponse
                        .find((item) => item.fillerwordsused)
                        ?.fillerwordsused.split(", ").length || 0) *
                        10
                  )}
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Filler words used in {name}'s Resume
            </h1>
            <div>
              {(() => {
                const wordsToRemove =
                  detailedResponse
                    .find((item) => item.fillerwordsused)
                    ?.fillerwordsused.split(", ") || [];
                const wordCount = wordsToRemove.length;

                let message = "";
                if (wordCount === 0) {
                  message =
                    "Great! Your resume doesn't contain any filler words that should be removed.";
                } else if (wordCount === 1) {
                  if (wordsToRemove[0] === "0" || wordsToRemove[0] === 0) {
                    message =
                      "Great! Your resume doesn't contain any filler words that should be removed.";
                  } else {
                    message =
                      "Your resume contains 1 filler word that should be removed. Consider removing it to improve your resume.";
                  }
                } else {
                  message = `Your resume contains ${wordCount} filler words that should be removed. Consider removing them to improve your resume.`;
                }

                return (
                  <p className="text-center underline font-medium">{message}</p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium">
              Filler words in resumes can dilute the impact of your achievements
              and waste valuable space. Words and phrases like "responsible
              for," "duties included," or "assisted with" add little value and
              can make your resume seem generic. Instead, focus on action verbs
              and specific accomplishments. Eliminate unnecessary adverbs and
              adjectives that don't add concrete information. For international
              students, avoiding filler words is particularly important as it
              helps maintain clarity and showcases language proficiency. A
              resume free of filler words is more concise, powerful, and likely
              to engage both ATS systems and human recruiters. It allows your
              true skills and experiences to stand out, increasing your chances
              of landing an interview in the competitive UK job market.
            </h1>
            <div className="h-24">
              <WordCloud
                keywords={
                  detailedResponse
                    .find((item) => item.fillerwordsused)
                    ?.fillerwordsused.split(", ") || []
                }
              />
            </div>
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={
                    detailedResponse.find((item) => item.sponsoredscore)
                      ?.sponsoredscore || 0
                  }
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              Visa Sponsorship Likelihood For {name}
            </h1>
            <div>
              {(() => {
                const wordsToRemove =
                  detailedResponse
                    .find((item) => item.fillerwordsused)
                    ?.fillerwordsused.split(", ") || [];
                const wordCount = wordsToRemove.length;

                let message = "";
                if (wordCount === 0) {
                  message =
                    "Great! Your resume doesn't contain any filler words that should be removed.";
                } else if (wordCount === 1) {
                  message =
                    "Your resume contains 1 filler word that should be removed. Consider removing it to improve your resume.";
                } else {
                  message = `Your resume contains ${wordCount} filler words that should be removed. Consider removing them to improve your resume.`;
                }

                return (
                  <p className="text-center blur-sm pointer-events-none select-none underline font-medium">
                    {message}
                  </p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium blur-sm pointer-events-none select-none">
              Filler words in resumes can dilute the impact of your achievements
              and waste valuable space. Words and phrases like "responsible
              for," "duties included," or "assisted with" add little value and
              can make your resume seem generic. Instead, focus on action verbs
              and specific accomplishments. Eliminate unnecessary adverbs and
              adjectives that don't add concrete information. For international
              students, avoiding filler words is particularly important as it
              helps maintain clarity and showcases language proficiency. A
              resume free of filler words is more concise, powerful, and likely
              to engage both ATS systems and human recruiters. It allows your
              true skills and experiences to stand out, increasing your chances
              of landing an interview in the competitive UK job market.
            </h1>
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={
                    detailedResponse.find((item) => item.sponsoredscore2)
                      ?.sponsoredscore2 || 0
                  }
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              UK Job Market Fit Score For {name}
            </h1>
            <div>
              {(() => {
                const wordsToRemove =
                  detailedResponse
                    .find((item) => item.fillerwordsused)
                    ?.fillerwordsused.split(", ") || [];
                const wordCount = wordsToRemove.length;

                let message = "";
                if (wordCount === 0) {
                  message =
                    "Great! Your resume doesn't contain any filler words that should be removed.";
                } else if (wordCount === 1) {
                  message =
                    "Your resume contains 1 filler word that should be removed. Consider removing it to improve your resume.";
                } else {
                  message = `Your resume contains ${wordCount} filler words that should be removed. Consider removing them to improve your resume.`;
                }

                return (
                  <p className="text-center blur-sm pointer-events-none select-none underline font-medium">
                    {message}
                  </p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium blur-sm pointer-events-none select-none">
              Filler words in resumes can dilute the impact of your achievements
              and waste valuable space. Words and phrases like "responsible
              for," "duties included," or "assisted with" add little value and
              can make your resume seem generic. Instead, focus on action verbs
              and specific accomplishments. Eliminate unnecessary adverbs and
              adjectives that don't add concrete information. For international
              students, avoiding filler words is particularly important as it
              helps maintain clarity and showcases language proficiency. A
              resume free of filler words is more concise, powerful, and likely
              to engage both ATS systems and human recruiters. It allows your
              true skills and experiences to stand out, increasing your chances
              of landing an interview in the competitive UK job market.
            </h1>
            <h1 className="text-center font-medium blur-sm pointer-events-none select-none">
              Filler words in resumes can dilute the impact of your achievements
              and waste valuable space. Words and phrases like "responsible
              for," "duties included," or "assisted with" add little value and
              can make your resume seem generic. Instead, focus on action verbs
              and specific accomplishments. Eliminate unnecessary adverbs and
              adjectives that don't add concrete information. For international
              students, avoiding filler words is particularly important as it
              helps maintain clarity and showcases language proficiency. A
              resume free of filler words is more concise, powerful, and likely
              to engage both ATS systems and human recruiters. It allows your
              true skills and experiences to stand out, increasing your chances
              of landing an interview in the competitive UK job market.
            </h1>
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={
                    detailedResponse.find((item) => item.sponsoredscore3)
                      ?.sponsoredscore3 || 0
                  }
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              UK Education Equivalency For {name}
            </h1>
            <div>
              {(() => {
                const wordsToRemove =
                  detailedResponse
                    .find((item) => item.fillerwordsused)
                    ?.fillerwordsused.split(", ") || [];
                const wordCount = wordsToRemove.length;

                let message = "";
                if (wordCount === 0) {
                  message =
                    "Great! Your resume doesn't contain any filler words that should be removed.";
                } else if (wordCount === 1) {
                  message =
                    "Your resume contains 1 filler word that should be removed. Consider removing it to improve your resume.";
                } else {
                  message = `Your resume contains ${wordCount} filler words that should be removed. Consider removing them to improve your resume.`;
                }

                return (
                  <p className="text-center blur-sm pointer-events-none select-none underline font-medium">
                    {message}
                  </p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium blur-sm pointer-events-none select-none">
              Filler words in resumes can dilute the impact of your achievements
              and waste valuable space. Words and phrases like "responsible
              for," "duties included," or "assisted with" add little value and
              can make your resume seem generic. Instead, focus on action verbs
              and specific accomplishments. Eliminate unnecessary adverbs and
              adjectives that don't add concrete information. For international
              students, avoiding filler words is particularly important as it
              helps maintain clarity and showcases language proficiency. A
              resume free of filler words is more concise, powerful, and likely
              to engage both ATS systems and human recruiters. It allows your
              true skills and experiences to stand out, increasing your chances
              of landing an interview in the competitive UK job market.
            </h1>
            <div className="flex justify-center mt-5">
              <div>
                <RadialProgress
                  percentage={
                    detailedResponse.find((item) => item.sponsoredscore4)
                      ?.sponsoredscore4 || 0
                  }
                />
              </div>
            </div>
            <h1 className="text-center font-extrabold my-4 text-lg">
              UK Cultural Fit Score For {name}
            </h1>
            <div>
              {(() => {
                const wordsToRemove =
                  detailedResponse
                    .find((item) => item.fillerwordsused)
                    ?.fillerwordsused.split(", ") || [];
                const wordCount = wordsToRemove.length;

                let message = "";
                if (wordCount === 0) {
                  message =
                    "Great! Your resume doesn't contain any filler words that should be removed.";
                } else if (wordCount === 1) {
                  message =
                    "Your resume contains 1 filler word that should be removed. Consider removing it to improve your resume.";
                } else {
                  message = `Your resume contains ${wordCount} filler words that should be removed. Consider removing them to improve your resume.`;
                }

                return (
                  <p className="text-center blur-sm pointer-events-none select-none underline font-medium">
                    {message}
                  </p>
                );
              })()}
            </div>
            <h1 className="text-center font-medium blur-sm pointer-events-none select-none">
              Filler words in resumes can dilute the impact of your achievements
              and waste valuable space. Words and phrases like "responsible
              for," "duties included," or "assisted with" add little value and
              can make your resume seem generic. Instead, focus on action verbs
              and specific accomplishments. Eliminate unnecessary adverbs and
              adjectives that don't add concrete information. For international
              students, avoiding filler words is particularly important as it
              helps maintain clarity and showcases language proficiency. A
              resume free of filler words is more concise, powerful, and likely
              to engage both ATS systems and human recruiters. It allows your
              true skills and experiences to stand out, increasing your chances
              of landing an interview in the competitive UK job market.
            </h1>
            {/* <h1 className="pt-5 text-center font-extrabold text-3xl">Want to see the full report?</h1> */}
            {/* <div className="flex justify-center">
              <img src={connectorLine} alt="connecter_line" />
            </div> */}
            <div className="relative">
              <div className="flex justify-center">
                {/* <button
                  onClick={() => handlePayment()}
                  className="bg-slate-800  no-underline group cursor-pointer relative shadow-sm shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6  text-white inline-block"
                >
                  <span className="absolute inset-0 overflow-hidden rounded-full">
                    <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
                  </span>
                  <div className="flex items-center space-x-2 justify-center z-10 rounded-full bg-zinc-950 py-3 px-8 ring-1 ring-white/10">
                    <LockOpenIcon className="h-5 w-5 mr-2" />
                    <span>
                      Unlock The Full Report Now <s>£29.99</s> £9.99
                    </span>
                  </div>
                  <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40" />
                </button> */}
              </div>
              {/* <p className="pt-1 text-center text-xs">
                Cutsomized CV Report Delivered Directly To Your E-mail Within 48
                Hours
              </p> */}
              {/* <div className="relative inset-0 flex items-center justify-center">
                <PricingBasic
                  headingBasic={
                    "Looking for more personalized advice and support?"
                  }
                  headingPricing={"Pay Now and Unlock Your Full Report"}
                />
              </div> */}
              <h1 className="pt-5 text-center font-extrabold text-2xl">
                Ready to find your Visa Sponsored job? Book a meeting today!
              </h1>
              <div className="flex justify-center my-5">
                <StepChart />
              </div>
              
            </div>
          </dl>
        </div>
      </div>
      <div className="-mt-24">
        <Testimonials/>
        <div className="flex justify-center">
          <button
            onClick={() => onContactFormOpen("Book a Free Call")}
            className="bg-slate-800  no-underline group cursor-pointer relative shadow-sm shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6  text-white inline-block"
          >
            <span className="absolute inset-0 overflow-hidden rounded-full">
              <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
            </span>
            <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40" />
          </button>
        </div>
      </div>
    </>
  );
}
