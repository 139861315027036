import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p3.jpeg"

const testimonial = {
  id: 1,
  stars: 5,
  quote: "After completing my Masters in Data Science at the University of Manchester, I was worried about finding a visa-sponsored job in the UK. UKWorkx's services were a game-changer. Their team guided me through every step, from optimizing my CV to preparing for technical interviews. Thanks to their support, I landed a role as a Data Scientist at a fintech startup in London, complete with visa sponsorship. I'm incredibly grateful for their expertise!",
  author: 'Aisha',
  jobTitle: 'Data Scientist, London',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/aisha-patel-datasci/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Our client, Aisha, an Indian national, completed her Masters in Data Science at the University of Manchester in June 2023. As an international student, she faced the challenge of finding a visa-sponsored job in the UK's competitive tech industry within a limited timeframe."
  },
  {
    title: "Challenges",
    content: [
      "Limited time to secure a job offer before her student visa expired",
      "Need for a role that offered Skilled Worker visa sponsorship",
      "Highly competitive job market in the UK tech sector",
      "Lack of extensive UK work experience",
      "Navigating the complex visa sponsorship process"
    ]
  },
  {
    title: "Solution",
    content: [
      "Tailored CV and LinkedIn profile optimization for data science roles",
      "Targeted job search focusing on companies with sponsorship licenses",
      "Technical interview preparation for data science positions",
      "Guidance on visa sponsorship requirements and processes",
      "Mock interviews and soft skills training"
    ],
    intro: "In July 2023, Aisha enrolled in our UKWorkx Elite plan, designed for international graduates seeking sponsorship. This £799 package included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a comprehensive review and optimization of Aisha's CV and online profiles",
      "Utilized our database to identify and apply to companies with a history of sponsoring skilled workers",
      "Provided intensive technical interview preparation, including SQL, Python, and machine learning concepts",
      "Offered guidance on navigating the UK's points-based immigration system",
      "Conducted bi-weekly strategy sessions to refine her job search approach",
      "Arranged mock interviews with industry professionals to improve her performance"
    ],
    intro: "Our team worked closely with Aisha to:"
  },
  {
    title: "Results",
    content: [
      "Aisha secured a Data Scientist position in London in October 2023",
      "The role came with full Skilled Worker visa sponsorship",
      "She received and accepted the job offer within 3 months of engaging our services",
      "Successfully transitioned from her student visa to a Skilled Worker visa",
      "Achieved her goal of starting her UK tech career within 4 months of graduation"
    ],
    intro: "Through our targeted approach and Aisha's dedication:"
  }
];

function CaseStudyAisha() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Client Success Story: From International Student to Sponsored Data Scientist in the UK
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyAisha;