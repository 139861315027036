import React from 'react';
import { Star } from 'lucide-react';
import image2 from "../../images/heroimages/p1.jpeg"


const testimonial = {
  id: 1,
  stars: 5,
  quote: "After completing my MSc in Data Science at the University of Westminster, I was eager to apply my skills in the UK's thriving tech industry. However, as an international student, the path to securing a sponsored position seemed daunting. UKWorkx's expertise was instrumental in my success. They helped me showcase my technical skills, navigate the complex job market, and connect with companies valuing diverse perspectives in data science. Thanks to their guidance, I landed an exciting role at a fintech startup. UKWorkx truly bridged the gap between my academic achievements and professional aspirations in the UK!",
  author: 'Ali',
  jobTitle: 'Data Scientist, London',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/ali-malik-datascience/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Ali, a Pakistani national, completed his MSc in Data Science at the University of Westminster in September 2022. With a strong foundation in machine learning, statistics, and big data analytics, he aimed to secure a position in the UK's competitive tech industry while navigating the complex visa sponsorship process."
  },
  {
    title: "Challenges",
    content: [
      "Highly competitive field with numerous qualified local graduates",
      "Need for a role offering Skilled Worker visa sponsorship in the tech sector",
      "Demonstrating practical data science skills alongside theoretical knowledge",
      "Limited professional network in the UK tech community",
      "Adapting to UK-specific data protection regulations and industry standards",
      "Showcasing unique value proposition as an international candidate"
    ]
  },
  {
    title: "Solution",
    content: [
      "Comprehensive review and optimization of CV highlighting data science projects and technical skills",
      "Creation of a GitHub portfolio showcasing coding skills and data analysis projects",
      "Targeted search for data science positions in companies known for diversity and international sponsorship",
      "Interview preparation focusing on technical challenges and real-world data science scenarios",
      "Networking strategies within the UK data science and tech startup community",
      "Guidance on UK-specific data protection laws and ethical AI practices"
    ],
    intro: "In July 2023, Ali enrolled in our UKWorkx Data Science Careers package, tailored for international graduates in the tech field. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a thorough review of Ali's CV, emphasizing his machine learning projects and data visualization skills",
      "Helped create a compelling GitHub portfolio, featuring projects in Python, R, and SQL",
      "Identified UK-based tech companies and startups with a track record of sponsoring international talent",
      "Provided intensive preparation for technical interviews, including mock coding challenges and data analysis tasks",
      "Arranged introductions to data science professionals in London's tech scene",
      "Offered guidance on presenting data science projects with a focus on business impact and ethical considerations"
    ],
    intro: "Our team worked closely with Ali to:"
  },
  {
    title: "Results",
    content: [
      "Ali secured a Data Scientist position at a fintech startup in London, in November 2023",
      "The role came with full Skilled Worker visa sponsorship",
      "He received interest from multiple tech companies, including both established firms and innovative startups",
      "Successfully transitioned from his student visa to a Skilled Worker visa",
      "Started his UK data science career within 2 months of graduation",
      "Gained a position that allows him to work on cutting-edge AI applications in the financial sector"
    ],
    intro: "Through our specialized approach and Ali's dedication:"
  }
];

function CaseStudyAli() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From Data Science Graduate to Sponsored Data Scientist in London's Fintech Scene
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image2}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyAli;