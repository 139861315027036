import { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';

export default function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasSeenBanner = localStorage.getItem('hasSeenCookieBanner');
    if (!hasSeenBanner) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    // Perform necessary actions for accepting cookies
    setShowBanner(false);
    localStorage.setItem('hasSeenCookieBanner', 'true');
  };

  const rejectCookies = () => {
    // Perform necessary actions for rejecting cookies
    setShowBanner(false);
    localStorage.setItem('hasSeenCookieBanner', 'true');
  };

  return (
    <Transition
      show={showBanner}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed z-50 inset-x-0 bottom-0 px-6 pb-6">
        <div className="mx-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
          <p className="text-sm leading-6 text-gray-900">
          This website uses cookies to help you cope with the stress of life's challenges. Our cookies are like virtual hugs, warming your soul and reminding you that everything will be okay, one delicious bite at a time. Accepting our cookies is entirely optional, but rejecting them might result in an existential crisis. We strongly recommend indulging in our cookie therapy, as it's been scientifically proven to increase your happiness levels by at least 73%.
          </p>
          <div className="mt-4 flex items-center gap-x-5">
            <button
              type="button"
              className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              onClick={acceptCookies}
            >
              Accept
            </button>
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={rejectCookies}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}