import React from 'react'
import Atschecker from "../features/atschecker/AtsChecker"
import Footer from "../features/footer/Footer"
import ContactForm from "../features/contactform/ContactForm"
import { useState } from 'react';
import TopBanner from '../features/banners/TopBanner';

function AtsChecker() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleContactFormOpen = (action) => {
    setSelectedAction(action);
    setShowContactForm(true);
  };

  const handleContactFormClose = () => {
    setShowContactForm(false);
    setSelectedAction('');
  };
  return (
    <>
    <TopBanner onContactFormOpen={handleContactFormOpen}/>
    <Atschecker onContactFormOpen={handleContactFormOpen}/>
    {showContactForm && (
        <ContactForm
          onClose={handleContactFormClose}
          selectedAction={selectedAction}
        />
      )}
    <Footer/>
    </>
  )
}

export default AtsChecker