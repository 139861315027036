import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function TopBanner({onContactFormOpen}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });

    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      setIsVisible(scrollPercentage > 8);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isVisible) {
      AOS.refresh();
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div 
      className="z-40 fixed top-0 left-0 right-0 flex items-center gap-x-6 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
      style={{
        background: 'linear-gradient(90deg, #000000 0%, #1a1a1a 50%, #000000 100%)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.3)'
      }}
      data-aos="fade-down"
      data-aos-offset="0"
    >
      <p className="text-sm leading-6 text-gray-200">
        <Link 
          to="#" 
          onClick={() => onContactFormOpen('I want to Book a demo')}
          className="hover:text-white transition-colors duration-300"
        >
          <strong className="font-semibold">UK Workx 2024</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Book a free 15 minutes call now with one of our consultants.&nbsp;
          <span aria-hidden="true" className="ml-1 inline-block transform transition-transform duration-300 group-hover:translate-x-1">&rarr;</span>
        </Link>
      </p>
      <div className="flex flex-1 justify-end">
      </div>
    </div>
  )
}