import review1 from "../../images/fiver1.png";
import review2 from "../../images/fiver2.png";
import review3 from "../../images/fiver3.png";
import review4 from "../../images/fiver4.png";
import review5 from "../../images/fiver5.png";
import React from "react";
import {
  CalendarDaysIcon,
  CreditCardIcon,
  UserCircleIcon,
  CogIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import Footer from "../footer/Footer";
import { useLocation, Navigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";

export default function AtsChecker({ onContactFormOpen }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const email = searchParams.get("email");
  const score = parseFloat(searchParams.get("score"));
  const scoreToSend = searchParams.get("score");
  const submissionDate = new Date(searchParams.get("submissionDate"));
  const criticism = searchParams.get("criticism");
  const detailedResponse = JSON.parse(searchParams.get("detailedResponse")
  );


  // Check if the required data is present in the URL query parameters
  if (!name || !email || isNaN(score) || !submissionDate || !criticism) {
    // Redirect the user to the ATS checker page or display an error message
    return <Navigate to="/atschecker" replace />;
    // Alternatively, you can display an error message
    // return <div>Invalid access. Please submit a CV first.</div>;
  }

  const getScoreStatus = () => {
    if (score < 2.2) {
      return { text: "Low", color: "bg-red-50 text-red-700 ring-red-600/20" };
    } else if (score < 3.6) {
      return {
        text: "Average",
        color: "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
      };
    } else {
      return {
        text: "High",
        color: "bg-green-50 text-green-700 ring-green-600/20",
      };
    }
  };

  const scoreStatus = getScoreStatus();
  return (
    <>
      
      <div className="bg-white">
        <Navbar />
        <main>
          <div className="relative isolate">
            <svg
              className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                  d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
              />
            </svg>
            <div
              className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
              aria-hidden="true"
            >
              <div
                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                style={{
                  clipPath:
                    "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
                }}
              />
            </div>
            <div className="overflow-hidden">
              <div className="mx-auto max-w-7xl px-6 pb-32 pt-12 sm:pt-60 lg:px-8 lg:pt-12">
                <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                  <div className="lg:col-start-3 lg:row-end-1">
                    <h2 className="sr-only">Summary</h2>
                    <div className="hidden sm:block">
                      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        No nonsense pricing. No hidden fees. No surprises.
                      </h1>
                      <p className="relative mt-6 text-lg leading-8 mb-3 text-gray-600 sm:max-w-md lg:max-w-none">
                      Our expertise lies in assisting international students and Post study work/Graduate visa holders secure sponsorship-eligible employment. With a remarkable 93% client satisfaction rate, our proven track record demonstrates our ability to consistently help job seekers land their ideal positions and achieve their career aspirations.
                      </p>
                    </div>
                    <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                      <dl className="flex flex-wrap">
                        <div className="flex-auto pl-6">
                          <dt className="text-sm font-semibold leading-6 text-gray-900">
                            ATS Score
                          </dt>
                          <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                            {score.toFixed(1)}
                          </dd>
                        </div>
                        <div className="flex-none self-end px-6 pt-4">
                          <dt className="sr-only">Status</dt>
                          <dd
                            className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${scoreStatus.color}`}
                          >
                            {scoreStatus.text}
                          </dd>
                        </div>
                        <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                          <dt className="flex-none">
                            <span className="sr-only">Client</span>
                            <UserCircleIcon
                              className="h-6 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="text-sm font-medium leading-6 text-gray-900">
                            {name}
                          </dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                          <dt className="flex-none">
                            <span className="sr-only">Submission date</span>
                            <CalendarDaysIcon
                              className="h-6 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="text-sm leading-6 text-gray-500">
                            <time
                              dateTime={
                                submissionDate.toISOString().split("T")[0]
                              }
                            >
                              {submissionDate.toLocaleDateString()}
                            </time>
                          </dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                          <dt className="flex-none">
                            <span className="sr-only">Email</span>
                            <CreditCardIcon
                              className="h-6 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="text-sm leading-6 text-gray-500">
                            {email}
                          </dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                          <dt className="flex-none">
                            <span className="sr-only">Email</span>
                            <CogIcon
                              className="h-6 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="text-sm leading-6 text-gray-500">
                            ATS checked against - Greenhouse/Workday/Ukworkx ATS
                          </dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                          <dt className="flex-none">
                            <span className="sr-only">Criticism</span>
                            <PencilSquareIcon
                              className="h-6 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="text-sm leading-6 text-gray-500">
                            {criticism}
                          </dd>
                        </div>
                      </dl>
                      <div className="mt-6 px-6 py-6 cursor-pointer text-white bg-gradient-to-br from-green-700 to-green-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-center">
                        <a
                          href={`/atsreport?score=${encodeURIComponent(
                            scoreToSend
                          )}&name=${encodeURIComponent(
                            name
                          )}&detailedResponse=${encodeURIComponent(
                            JSON.stringify(detailedResponse)
                          )}`}
                          className="text-lg font-bold leading-6"
                        >
                          View Detailed Report{" "}
                          <span aria-hidden="true">&rarr;</span>
                        </a>
                      </div>
                    </div>
                    <div className="mt-4 sm:hidden">
                      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        No nonsense pricing. No hidden fees. No surprises.
                      </h1>
                      <p className="relative mt-6 text-lg leading-8 mb-3 text-gray-600 sm:max-w-md lg:max-w-none">
                      Our expertise lies in assisting international students and Post study work/Graduate visa holders secure sponsorship-eligible employment. With a remarkable 93% client satisfaction rate, our proven track record demonstrates our ability to consistently help job seekers land their ideal positions and achieve their career aspirations.
                      </p>
                    </div>
                  </div>
                  <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                    <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                      <div className="relative">
                        <img
                          src={review1}
                          alt="reviews"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                      </div>
                    </div>
                    <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                      <div className="relative">
                        <img
                          src={review2}
                          alt="reviews"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                      </div>
                      <div className="relative">
                        <img
                          src={review4}
                          alt="reviews"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                      </div>
                    </div>
                    <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                      <div className="relative">
                        <img
                          src={review5}
                          alt="reviews"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                      </div>
                      <div className="relative">
                        <img
                          src={review3}
                          alt="reviews"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}