import Navbar from "../features/navbar/Navbar"
import Footer from "../features/footer/Footer"
import { useState } from 'react';
import TopBanner from "../features/banners/TopBanner"
import ContactForm from "../features/contactform/ContactForm"
import userImage from "../images/userimage.png"
import blogImage1 from "../images/atsblog1.jpg"

const posts = [
    {
      id: 1,
      title: 'How does our ATS checker work?',
      href: '/atshow',
      description:
        'Beating the Bots: How Our AI-Powered ATS Optimization Helps Job Seekers Get Past Resume Screeners.',
      imageUrl:
        blogImage1,
      date: 'Mar 16, 2024',
      datetime: '2024-03-16',
      category: { title: 'ATS tech', href: '#' },
      author: {
        name: 'Ankit',
        role: 'Marketing and Growth',
        href: '/atshow',
        imageUrl:
          userImage,
      },
    },
    // {
    //     id: 2,
    //     title: 'Auto Job Applier: Unlock Sponsored Roles in the UK',
    //     href: '/autoapply',
    //     description:
    //       'The Auto Job Applier is a powerful tool designed to help international job seekers navigate the competitive UK sponsored job market. By harnessing advanced algorithms and automation, it streamlines your search and maximizes your chances of success.',
    //     imageUrl:
    //       blogImage2,
    //     date: 'Mar 20, 2024',
    //     datetime: '2024-03-20',
    //     category: { title: 'Auto Apply', href: '#' },
    //     author: {
    //       name: 'Ankit',
    //       role: 'Marketing and Growth',
    //       href: '/autoapply',
    //       imageUrl:
    //         userImage,
    //     },
    //   },
  ]
  
  export default function BlogPage() {
    const [showContactForm, setShowContactForm] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleContactFormOpen = (action) => {
    setSelectedAction(action);
    setShowContactForm(true);
  };

  const handleContactFormClose = () => {
    setShowContactForm(false);
    setSelectedAction('');
  };
    return (
        <>
        <TopBanner onContactFormOpen={handleContactFormOpen} />
        {showContactForm && (
        <ContactForm
          onClose={handleContactFormClose}
          selectedAction={selectedAction}
        />
      )}
        <Navbar/>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
            
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.id} className="flex flex-col items-start justify-between">
                <div className="relative w-full">
                  <img
                    src={post.imageUrl}
                    alt=""
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {post.date}
                    </time>
                    <a
                      href={post.category.href}
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.category.title}
                    </a>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4">
                    <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <a href={post.author.href}>
                          <span className="absolute inset-0" />
                          {post.author.name}
                        </a>
                      </p>
                      <p className="text-gray-600">{post.author.role}</p>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
        <Footer/>
      </>
    )
  }
  