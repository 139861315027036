import {InformationCircleIcon } from '@heroicons/react/20/solid'
import atsblog1 from "../../images/atsblog1.jpg"
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import TopBanner from "../banners/TopBanner"

export default function AtsHow({onContactFormOpen}) {
  return (
    <>
    <TopBanner onContactFormOpen={onContactFormOpen} />
    <Navbar />
    <div className="bg-white px-6 py-8 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-green-600">How did we?</p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Beating the Bots: How Our AI-Powered ATS Optimization Helps International Students Looking For Visa Sponsored Jobs Get Past Resume Screeners</h1>
        <p className="mt-6 text-xl leading-8">
          Getting your resume past applicant tracking systems (ATS) and into the hands of a real person is one of the biggest hurdles job seekers face today, especially for international students seeking sponsored roles. According to research, over 90% of large companies use ATS to filter and rank job applicants. This means your carefully crafted resume could get automatically rejected before a human ever sees it.
        </p>
        <div className="mt-10 max-w-2xl">
          <p>
            To help job seekers overcome this challenge, our team of data scientists and machine learning engineers developed a proprietary AI system that optimizes resumes to get past ATS screeners, especially for international students applying to sponsored jobs. Here's how it works:
          </p>
          <p className="mt-8">
            First, we processed tens of thousands of resumes through the screening algorithms of top ATS providers like Taleo, Workday, Greenhouse, and iCIMS. By analyzing which resumes made it through and which were rejected, we reverse engineered the key parameters these systems use to filter out applicants.
          </p> 
          <p className="mt-8">
            We discovered that ATS tend to penalize international students in particular ways - for example, rejecting resumes that mention sponsorship needs or have education/work experience from abroad. Foreign-sounding names can also trigger automatic rejections.
          </p>
          <p className="mt-8">
            Armed with these insights, we built a machine learning model to modify resumes to avoid common triggers for rejection while still highlighting the candidate's strengths. Our model adjusts things like phrasing, format, and keywords to make the resume as ATS-friendly as possible.
          </p>
          <p className="mt-8">
            For example, it may suggest alternative wording for sponsorship needs, reorganize sections to emphasize UK experience first, and sprinkle in keywords from the job description that the ATS is likely scanning for. The end result is a resume that still represents the candidate authentically but is reformatted and optimized to bypass the bots and impress the humans.
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Integrating State-of-the-Art Language AI</h2>
          <p className="mt-6">
            To take our optimization even further, we integrated the API for Anthropic's state-of-the-art language model, Claude, which powers the AI behind our suggestions. Claude deeply understands language and context, allowing our system to give nuanced, tailored feedback.
          </p>
          <p className="mt-8">
            Working in tandem with the ATS algorithm insights from our machine learning model, Claude provides detailed, actionable guidance to make each resume as strong as possible while avoiding the ATS traps laid for international students and other frequently screened-out applicants. This potent combination of ATS hacking and best-in-class language AI gives job seekers, especially international students, their best shot at getting past the bots and landing an interview.
          </p>
        </div>
        <figure className="mt-16">
          <img
            className="aspect-video rounded-xl bg-gray-50 object-cover"
            src={atsblog1}
            alt=""
          />
          <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
            By combining data science, machine learning, and cutting-edge language AI, we've built a powerful tool to optimize resumes and help job seekers achieve their career aspirations.
          </figcaption>
        </figure>
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Leveling the Playing Field</h2>
          <p className="mt-6">
            While the technical details are complex, the goal is simple - level the playing field for job seekers so that talented candidates don't get unfairly screened out. Our AI-powered ATS optimization is helping job seekers, especially international students pursuing sponsored roles, get their resumes in front of hiring managers and land more interviews.
          </p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  )
}