import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p10.jpeg"


const testimonial = {
  id: 1,
  stars: 5,
  quote: "After completing my MSc in Cyber Security at Cardiff University, I was determined to contribute to the UK's rapidly evolving cybersecurity landscape. However, finding a sponsored position in this sensitive field seemed like an uphill battle. UKWorkx's expertise was invaluable. They helped me navigate the complex security clearance processes, showcase my technical skills, and connect with companies valuing international perspectives in cybersecurity. Thanks to their guidance, I secured a role at a leading cyber defense firm. UKWorkx truly helped me break into the UK's cybersecurity sector!",
  author: 'Hussain',
  jobTitle: 'Cyber Security Analyst, London',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/hussain-al-farsi-cybersec/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Hussain, an Omani national, completed his MSc in Cyber Security at Cardiff University in September 2023. With a strong foundation in network security and ethical hacking, he aimed to secure a position in the UK's cutting-edge cybersecurity industry while navigating the complex visa sponsorship process and security clearance requirements."
  },
  {
    title: "Challenges",
    content: [
      "Highly sensitive field with stringent security clearance requirements for many positions",
      "Need for a role offering Skilled Worker visa sponsorship in the cybersecurity sector",
      "Competing with local graduates who may have easier access to security clearances",
      "Limited professional network in the UK cybersecurity community",
      "Demonstrating practical skills alongside theoretical knowledge in a rapidly evolving field",
      "Navigating the balance between disclosing skills and maintaining project confidentiality"
    ]
  },
  {
    title: "Solution",
    content: [
      "Comprehensive review and optimization of CV highlighting cybersecurity projects and certifications",
      "Targeted search for graduate positions in cybersecurity firms open to sponsoring international talent",
      "Interview preparation focusing on technical scenarios and ethical hacking challenges",
      "Networking strategies within the UK cybersecurity community, including attendance at relevant conferences",
      "Guidance on UK-specific cybersecurity regulations and data protection laws",
      "Support in creating a portfolio showcasing cybersecurity skills without compromising sensitive information"
    ],
    intro: "In July 2023, Hussain enrolled in our UKWorkx Elite Plan, tailored for international graduates. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a thorough review of Hussain's CV, emphasizing his ethical hacking skills and cybersecurity certifications",
      "Identified UK-based cybersecurity companies with a track record of sponsoring international talent",
      "Provided intensive preparation for technical interviews, including mock penetration testing scenarios",
      "Arranged introductions to professionals in the UK cybersecurity sector, focusing on firms that value international perspectives",
      "Offered guidance on UK cybersecurity standards and compliance requirements",
      "Helped create a GitHub portfolio showcasing coding skills relevant to cybersecurity without revealing sensitive project details"
    ],
    intro: "Our team worked closely with Hussain to:"
  },
  {
    title: "Results",
    content: [
      "Hussain secured a Cyber Security Analyst position in London in November 2023",
      "The role came with full Skilled Worker visa sponsorship and support for necessary security clearances",
      "He received interest from multiple cybersecurity firms, including both private sector and government contractors",
      "Successfully transitioned from his student visa to a Skilled Worker visa",
      "Started his UK cybersecurity career within 2 months of graduation",
      "Gained a position that allows him to work on cutting-edge cyber defense strategies"
    ],
    intro: "Through our specialized approach and Hussain's dedication:"
  }
];

function CaseStudyHussain() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From Cyber Security Graduate to Sponsored Security Analyst in London
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyHussain;