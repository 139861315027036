import { motion } from "framer-motion";
import { useState } from "react";
import { FiMenu, FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png"

const Navbar = ({onContactFormOpen}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`bg-white z-40 ${isOpen ? 'mb-20' : ''}`}>
      <FlipNav isOpen={isOpen} setIsOpen={setIsOpen} onContactFormOpen={onContactFormOpen} />
      <div className="z-40" />
    </div>
  );
};

const FlipNav = ({ isOpen, setIsOpen, onContactFormOpen }) => {
  return (
    <nav className="z-50 bg-white p-4 border-b-[1px] border-gray-200 flex items-center justify-between relative">
      <NavLeft setIsOpen={setIsOpen} />
      <NavRight onContactFormOpen={onContactFormOpen} />
      <NavMenu isOpen={isOpen} />
    </nav>
  );
};

const Logo = () => {
  return (
    <Link to="/" className="lg:hidden absolute left-1/2 transform -translate-x-1/2">
      <img src={logo} alt="Logo" className="h-8 w-auto" />
    </Link>
  );
};

const NavLeft = ({ setIsOpen }) => {
  return (
    <div className="flex items-center gap-6">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="block lg:hidden text-gray-950 text-2xl"
        onClick={() => setIsOpen((pv) => !pv)}
      >
        <FiMenu />
      </motion.button>
      <div className="hidden lg:block">
        <Logo />
      </div>
      <NavLink text="Home" to="/" />
      <NavLink text="ATS Checker" to="/atschecker" />
      <NavLink text="Sponsored Jobs Board" to="https://jobs.ukworkx.com" external={true} />
      <NavLink text="Pricing" to="/pricing" />
      <NavLink text="Success Stories" to="/casestudies" />
      <NavLink text="Blog" to="/blog" />
    </div>
  );
};

const NavLink = ({ text, to, external = false }) => {
  if (external) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className="hidden lg:block h-[30px] overflow-hidden font-medium"
      >
        <motion.div whileHover={{ y: -30 }}>
          <span className="flex items-center h-[30px] text-gray-500">{text}</span>
          <span className="flex items-center h-[30px] text-indigo-600">
            {text}
          </span>
        </motion.div>
      </a>
    );
  }

  return (
    <Link
      to={to}
      className="hidden lg:block h-[30px] overflow-hidden font-medium"
    >
      <motion.div whileHover={{ y: -30 }}>
        <span className="flex items-center h-[30px] text-gray-500">{text}</span>
        <span className="flex items-center h-[30px] text-indigo-600">
          {text}
        </span>
      </motion.div>
    </Link>
  );
};

const NavRight = ({onContactFormOpen}) => {
  return (
    <div className="flex items-center gap-4">
      <Logo />
      <button onClick={() => onContactFormOpen('I want to Book a demo')}>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-2 py-1.5 bg-gradient-to-r from-violet-600 to-indigo-600 text-white font-medium rounded-md whitespace-nowrap hidden sm:block"
        >
          Book a Free 15 Minutes Call
        </motion.button>
      </button>
    </div>
  );
};

const NavMenu = ({ isOpen }) => {
  return (
    <motion.div
      variants={menuVariants}
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      className="absolute p-4 bg-white shadow-lg left-0 right-0 top-full origin-top flex flex-col gap-4"
    >
      <MenuLink text="Home" to="/" />
      <MenuLink text="ATS Checker" to="/atschecker" />
      <MenuLink text="Sponsored Jobs Board" to="https://jobs.ukworkx.com" external={true} />
      <MenuLink text="Pricing" to="/pricing" />
      <MenuLink text="Success Stories" to="/casestudies" />
    </motion.div>
  );
};

const MenuLink = ({ text, to, external = false }) => {
  const LinkComponent = external ? 'a' : Link;
  const linkProps = external ? { href: to, target: "_blank", rel: "noopener noreferrer" } : { to };

  return (
    <motion.div
      variants={menuLinkVariants}
      className="h-[30px] overflow-hidden font-medium text-lg flex items-start gap-2"
    >
      <motion.span variants={menuLinkArrowVariants}>
        <FiArrowRight className="h-[30px] text-gray-950" />
      </motion.span>
      <LinkComponent {...linkProps}>
        <motion.div whileHover={{ y: -30 }}>
          <span className="flex items-center h-[30px] text-gray-500">{text}</span>
          <span className="flex items-center h-[30px] text-indigo-600">
            {text}
          </span>
        </motion.div>
      </LinkComponent>
    </motion.div>
  );
};

export default Navbar;

const menuVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const menuLinkVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: -10,
    opacity: 0,
  },
};

const menuLinkArrowVariants = {
  open: {
    x: 0,
  },
  closed: {
    x: -4,
  },
};