import React from 'react';

const WordCloud = ({ keywords }) => {
  return (
    <div className="flex flex-wrap justify-center items-center mx-auto max-w-lg">
      {keywords.map((keyword, index) => (
        <span
          key={index}
          className="inline-block px-3 py-1 m-1 text-sm font-medium text-gray-800 bg-gray-200 rounded-full shadow-sm"
        >
          {keyword}
        </span>
      ))}
    </div>
  );
};

export default WordCloud;