import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";
import useMeasure from "react-use-measure";

const FaqSection = () => {
  return (
    <div className="px-4 py-12">
      <div className="mx-auto max-w-3xl">
        <h3 className="mb-4 text-center text-3xl font-semibold">
          Frequently asked questions
        </h3>
        <Question title="What is UKworkx, and how can it help me in my job search?" defaultOpen>
          <p>
          UKworkx is a comprehensive career consultation service that offers personalized guidance, profile rebranding, and automated job applications to help you achieve your career goals and land your dream job.
          </p>
        </Question>
        <Question title="How does UKworkx's Auto Apply service work?">
          <p>
          Our Auto Apply service creates tailored job applications that highlight your skills and experience. Our team then submit these applications to job openings that match your career goals and qualifications, saving you time and effort.
          </p>
        </Question>
        <Question title="Does UKworkx offer any support for international job seekers?">
          <p>
          Yes, UKworkx provides visa guidance for those seeking international career opportunities. Our experts will help you navigate the complex world of work visas and understand the requirements and processes involved.
          </p>
        </Question>
        <Question title="How long does the UKworkx End-to-End Mentoring program last?">
          <p>
          The duration of the End-to-End Mentoring program varies depending on your individual needs and goals. Typically, the program lasts for several months, with bi-weekly consultations*(Elite pack only) to provide ongoing support and guidance. See terms and conditions for more details.
          </p>
        </Question>
        <Question title="Can UKworkx help me with interview preparation?">
          <p>
          Absolutely! Our career consultants will help you develop the confidence and skills needed to excel in job interviews. We provide mock interview sessions, feedback, and insider tips to ensure you make a lasting impression on potential employers.
          </p>
        </Question>
        <Question title="How much does UKworkx's service cost?">
          <p>
          UKworkx offers a range of packages and pricing options to suit different needs and budgets. Please contact our team for more information on our current pricing and available packages.
          </p>
        </Question>
        <Question title="Is UKworkx's service confidential?">
          <p>
          Yes, we take your privacy and confidentiality seriously. All information you share with UKworkx, including your personal details, career goals, and job search progress, is kept strictly confidential and will not be shared with any third parties without your explicit consent. However, we may use anonymized data for research and analysis purposes. See our privacy policy for more details.
          </p>
        </Question>
        <Question title="How do I get started with UKworkx?">
          <p>
          To get started, simply visit our website and fill out the contact form to schedule a consultation with one of our career experts. We'll work with you to understand your needs and goals and recommend the best package or service to help you achieve your career aspirations.
          </p>
        </Question>
      </div>
    </div>
  );
};

const Question = ({ title, children, defaultOpen = false }) => {
  const [ref, { height }] = useMeasure();
  const [open, setOpen] = useState(defaultOpen);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className="border-b-[1px] border-b-slate-300"
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="flex w-full items-center justify-between gap-4 py-6"
      >
        <motion.span
          variants={{
            open: {
              color: "rgba(3, 6, 23, 0)",
            },
            closed: {
              color: "rgba(3, 6, 23, 1)",
            },
          }}
          className="bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-left text-lg font-medium"
        >
          {title}
        </motion.span>
        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "rgb(124 58 237)",
            },
            closed: {
              rotate: "0deg",
              color: "#030617",
            },
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? height : "0px",
          marginBottom: open ? "24px" : "0px",
        }}
        className="overflow-hidden text-slate-600"
      >
        <p ref={ref}>{children}</p>
      </motion.div>
    </motion.div>
  );
};

export default FaqSection;