import { motion } from "framer-motion";
import image1 from "../../images/heroimages/p1.jpeg"
import image3 from "../../images/heroimages/p3.jpeg"
import image5 from "../../images/heroimages/p5.jpeg"
import image6 from "../../images/heroimages/p6.jpeg"
import image7 from "../../images/heroimages/p7.jpeg"
import image8 from "../../images/heroimages/p8.jpeg"
import image10 from "../../images/heroimages/p10.jpeg"
import image12 from "../../images/heroimages/p12.jpeg"
import image13 from "../../images/heroimages/p13.jpeg"
import image17 from "../../images/saurav.png"

const Testimonials = () => {
  return (
    <div className="bg-slate-950 py-12">
      <div className="mb-8 px-4">
        <h3 className="text-slate-50 text-4xl font-semibold text-center">
          Testimonials
        </h3>
        <p className="text-center text-slate-300 text-sm mt-2 max-w-lg mx-auto">
          Hear from our successful candidates who secured sponsored jobs in the UK. Their stories are a testament to the power of UKWorkx's specialized services in bridging the gap between international talent and UK employers.
        </p>
      </div>
      <div className="p-4 overflow-x-hidden relative">
        <div className="absolute top-0 bottom-0 left-0 w-24 z-10 bg-gradient-to-r from-slate-900 to-transparent" />

        <div className="flex items-center mb-4">
          <TestimonialList list={testimonials.top} duration={125} />
          <TestimonialList list={testimonials.top} duration={125} />
          <TestimonialList list={testimonials.top} duration={125} />
        </div>
        <div className="flex items-center">
          <TestimonialList list={testimonials.bottom} duration={275} />
          <TestimonialList list={testimonials.bottom} duration={275} />
          <TestimonialList list={testimonials.bottom} duration={275} />
        </div>

        <div className="absolute top-0 bottom-0 right-0 w-24 z-10 bg-gradient-to-l from-slate-900 to-transparent" />
      </div>
    </div>
  );
};

const TestimonialList = ({ list, reverse = false, duration = 50 }) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-100%" : "0%" }}
      animate={{ translateX: reverse ? "0%" : "-100%" }}
      transition={{ duration, repeat: Infinity, ease: "linear" }}
      className="flex gap-4 px-2"
    >
      {list.map((t) => {
        return (
          <div
            key={t.id}
            className="shrink-0 w-[500px] grid grid-cols-[7rem,_1fr] rounded-lg overflow-hidden relative"
          >
            <img src={t.img} className="w-full h-full object-cover" />
            <div className="bg-slate-900 text-slate-50 p-4">
              <span className="block font-semibold text-lg mb-1">{t.name}</span>
              <span className="block mb-3 text-xs font-medium">{t.title}</span>
              <span className="block text-xs text-slate-300">{t.info}</span>
            </div>
            <span className="text-7xl absolute top-2 right-2 text-slate-700">
              "
            </span>
          </div>
        );
      })}
    </motion.div>
  );
};

const testimonials = {
  top: [
    {
      id: 1,
      img: image1,
      name: "Ali",
      title: "Data Scientist, London",
      info: "After completing my MSc in Data Science at the University of Westminster, I was eager to apply my skills in the UK's thriving tech industry. However, as an international student, the path to securing a sponsored position seemed daunting. UKWorkx's expertise was instrumental in my success. They helped me showcase my technical skills, navigate the complex job market, and connect with companies valuing diverse perspectives in data science. Thanks to their guidance, I landed an exciting role at a fintech startup. UKWorkx truly bridged the gap between my academic achievements and professional aspirations in the UK!",
    },
    {
      id: 2,
      img: image17,
      name: "Saurav Banojia,",
      title: "Management Accountant @BBC London",
      info: "As a recent graduate new to the UK job market, I decided to use UKWorkx's auto-apply service. Their team was incredibly helpful in preparing me for interviews and navigating the local job landscape. Thanks to their guidance, which I started using before graduation, I successfully secured a position as a Management Accountant at the BBC. I highly recommend their services for anyone in a similar situation.",
    },
    {
      id: 3,
      img:  image3,
      name: "Aisha",
      title: "Data Scientist, London",
      info: "After completing my Masters in Data Science at the University of Manchester, I was worried about finding a visa-sponsored job in the UK. UKWorkx's services were a game-changer. Their team guided me through every step, from optimizing my CV to preparing for technical interviews. Thanks to their support, I landed a role as a Data Scientist at a fintech startup in London, complete with visa sponsorship. I'm incredibly grateful for their expertise!",
    },
    {
      id: 4,
      img:  image12,
      name: "Yuki",
      title: "Research Scientist, Cambridge",
      info: "As a Japanese biomedical researcher, I dreamed of contributing to the UK's innovative life sciences sector. However, the path seemed daunting, especially with the language barrier and complex visa requirements. UKWorkx's specialized guidance was a game-changer. They helped me showcase my research skills, navigate the sponsorship process, and connect with leading research institutions. Thanks to their support, I'm now part of a cutting-edge research team in Cambridge. UKWorkx truly bridged the gap between my aspirations and reality!",
    },
    {
      id: 5,
      img: image8,
      name: "Elena",
      title: "Junior Architect, London",
      info: "As a Brazilian architect with a dream of working in the UK, I faced numerous challenges in navigating the job market and visa requirements. UKWorkx's expertise was invaluable. Their team not only helped me tailor my portfolio for the UK market but also connected me with firms offering sponsorship. Thanks to their guidance, I secured a position at a prestigious architectural firm in London. UKWorkx turned my dream into reality!",
    },
    {
      id: 6,
      img: image5,
      name: "Hassan",
      title: "Renewable Energy Consultant, Manchester",
      info: "After finishing my Masters in Sustainable Energy Systems at the University of Edinburgh, I was determined to contribute to the UK's renewable energy sector. However, finding a sponsored position seemed daunting. UKWorkx's specialized services were invaluable. They helped me navigate the complex job market, polish my technical skills, and connect with the right employers. Thanks to their guidance, I secured a role as a Renewable Energy Consultant with visa sponsorship. I'm now living my dream of contributing to a sustainable future in the UK!",
    },
  ],
  
  bottom: [
    {
      id: 1,
      img:  image6,
      name: "Kareena",
      title: "Physiotherapist @Brighton Rehabilitation Centre",
      info: "After completing my MSc in Physiotherapy at the University of Sussex, I was eager to apply my skills in the UK's healthcare system. However, finding a sponsored position as an international physiotherapist seemed incredibly challenging. UKWorkx's expertise was a game-changer. They guided me through the complex process of HCPC registration, helped me showcase my clinical skills, and connected me with healthcare providers open to sponsoring international talent. Thanks to their support, I secured a rewarding role at a leading rehabilitation center. UKWorkx truly helped me turn my passion for physiotherapy into a fulfilling career in the UK!",
    },
    {
      id: 2,
      img: image7,
      name: "Pratik",
      title: "Aerospace Design Engineer, Bristol",
      info: "After completing my MSc in Aerospace Engineering at the University of Bath, I was determined to launch my career in the UK's cutting-edge aerospace industry. However, navigating the job market and visa requirements seemed overwhelming. UKWorkx's expertise was invaluable. They helped me showcase my technical skills, connect with aerospace companies, and navigate the complex sponsorship process. Thanks to their support, I landed a dream role at a leading aerospace firm. UKWorkx truly helped me soar to new heights in my career!",
    },
    {
      id: 3,
      img: image13,
      name: "Kriteshini",
      title: "Digital Marketing Specialist, London",
      info: "After completing my MSc in Marketing at Brunel University, I was eager to apply my skills in the dynamic UK market. However, finding a sponsored position in marketing seemed like a daunting task. UKWorkx's expertise was a game-changer. They helped me showcase my digital marketing skills, connect with innovative companies, and navigate the intricate visa process. Thanks to their guidance, I secured an exciting role at a fast-growing tech startup. UKWorkx truly helped me turn my marketing aspirations into reality!",
    },
    {
      id: 4,
      img: image10,
      name: "Hussain",
      title: "Cyber Security Analyst, London",
      info: "After completing my MSc in Cyber Security at Cardiff University, I was determined to contribute to the UK's rapidly evolving cybersecurity landscape. However, finding a sponsored position in this sensitive field seemed like an uphill battle. UKWorkx's expertise was invaluable. They helped me navigate the complex security clearance processes, showcase my technical skills, and connect with companies valuing international perspectives in cybersecurity. Thanks to their guidance, I secured a role at a leading cyber defense firm. UKWorkx truly helped me break into the UK's cybersecurity sector!"
,
    }
  ],
};

export default Testimonials;