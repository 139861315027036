import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p6.jpeg"


const testimonial = {
  id: 1,
  stars: 5,
  quote: "After completing my MSc in Physiotherapy at the University of Sussex, I was eager to apply my skills in the UK's healthcare system. However, finding a sponsored position as an international physiotherapist seemed incredibly challenging. UKWorkx's expertise was a game-changer. They guided me through the complex process of HCPC registration, helped me showcase my clinical skills, and connected me with healthcare providers open to sponsoring international talent. Thanks to their support, I secured a rewarding role at a leading rehabilitation center. UKWorkx truly helped me turn my passion for physiotherapy into a fulfilling career in the UK!",
  author: 'Kareena',
  jobTitle: 'Physiotherapist @Brighton Rehabilitation Centre',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/kareena-patel-physio/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Kareena, an Indian national, completed her MSc in Physiotherapy at the University of Sussex in July 2023. With a strong foundation in evidence-based practice and a passion for rehabilitation, she aimed to secure a position in the UK's healthcare sector while navigating the complex visa sponsorship process and professional registration requirements."
  },
  {
    title: "Challenges",
    content: [
      "Highly regulated field requiring Health and Care Professions Council (HCPC) registration",
      "Need for a role offering Skilled Worker visa sponsorship in the healthcare sector",
      "Competing with local graduates who are already familiar with the UK healthcare system",
      "Limited professional network in the UK physiotherapy community",
      "Adapting to UK-specific clinical practices and healthcare protocols",
      "Demonstrating practical skills alongside theoretical knowledge in a patient-centered environment"
    ]
  },
  {
    title: "Solution",
    content: [
      "Comprehensive review and optimization of CV highlighting clinical placements and specialized skills",
      "Guidance through the HCPC registration process for international physiotherapists",
      "Targeted search for physiotherapy positions in NHS trusts and private clinics open to sponsoring international talent",
      "Interview preparation focusing on clinical scenarios and patient case studies",
      "Networking strategies within the UK physiotherapy community, including professional body memberships",
      "Support in creating a portfolio showcasing clinical skills and continued professional development"
    ],
    intro: "In May 2023, Kareena enrolled in our UKWorkx Healthcare Professionals package, tailored for international graduates in regulated health professions. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a thorough review of Kareena's CV, emphasizing her clinical experiences and specialized physiotherapy techniques",
      "Provided step-by-step assistance with the HCPC registration application, including document preparation",
      "Identified healthcare providers with a track record of sponsoring international physiotherapists",
      "Offered intensive preparation for clinical interviews, including mock patient assessment scenarios",
      "Arranged introductions to physiotherapy professionals in the UK, focusing on rehabilitation centers and sports clinics",
      "Assisted in compiling a comprehensive CPD portfolio to demonstrate commitment to ongoing learning"
    ],
    intro: "Our team worked closely with Kareena to:"
  },
  {
    title: "Results",
    content: [
      "Kareena secured a Physiotherapist position at Brighton Rehabilitation Centre in September 2023",
      "The role came with full Skilled Worker visa sponsorship",
      "She successfully obtained HCPC registration, allowing her to practice as a physiotherapist in the UK",
      "Received interest from both NHS trusts and private clinics",
      "Successfully transitioned from her student visa to a Skilled Worker visa",
      "Started her UK physiotherapy career within 2 months of graduation",
      "Gained a position that allows her to specialize in neurological rehabilitation"
    ],
    intro: "Through our specialized approach and Kareena's dedication:"
  }
];

function CaseStudyKareena() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From Physiotherapy Graduate to Sponsored Practitioner in Brighton
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyKareena;