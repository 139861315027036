import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p5.jpeg"


const testimonial = {
  id: 1,
  stars: 5,
  quote: "After finishing my Masters in Sustainable Energy Systems at the University of Edinburgh, I was determined to contribute to the UK's renewable energy sector. However, finding a sponsored position seemed daunting. UKWorkx's specialized services were invaluable. They helped me navigate the complex job market, polish my technical skills, and connect with the right employers. Thanks to their guidance, I secured a role as a Renewable Energy Consultant with visa sponsorship. I'm now living my dream of contributing to a sustainable future in the UK!",
  author: 'Hassan',
  jobTitle: 'Renewable Energy Consultant, Manchester',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/hassan-malik-renewables/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Hassan, a Pakistani national, completed his Masters in Sustainable Energy Systems at the University of Edinburgh in August 2023. He aimed to leverage his expertise in the UK's growing renewable energy sector but faced the challenge of securing a visa-sponsored position in a highly specialized field."
  },
  {
    title: "Challenges",
    content: [
      "Specialized field with limited job openings",
      "Need for a role offering Skilled Worker visa sponsorship",
      "Competing with local graduates and experienced professionals",
      "Limited professional network in the UK energy sector",
      "Balancing job search with completing final Masters project"
    ]
  },
  {
    title: "Solution",
    content: [
      "Sector-specific CV and cover letter optimization",
      "Targeted job search in the renewable energy industry",
      "Technical interview preparation for energy consultant roles",
      "Networking strategies for the UK energy sector",
      "Guidance on visa sponsorship and energy sector regulations"
    ],
    intro: "In June 2023, Hassan enrolled in our UKWorkx Specialized Sector package, tailored for graduates in niche industries. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted in-depth research on the UK renewable energy market and key players",
      "Optimized Hassan's CV and LinkedIn profile to highlight his specialized skills and project experience",
      "Provided intensive interview preparation, including technical questions on renewable energy technologies and policies",
      "Arranged informational interviews with professionals in the UK energy sector",
      "Offered guidance on presenting his Masters project to potential employers",
      "Assisted in preparing a portfolio of his sustainable energy projects"
    ],
    intro: "Our team collaborated closely with Hassan to:"
  },
  {
    title: "Results",
    content: [
      "Hassan secured a Renewable Energy Consultant position in Manchester in November 2023",
      "The role came with full Skilled Worker visa sponsorship",
      "He received multiple job offers, allowing him to choose the best fit for his career goals",
      "Successfully transitioned from his student visa to a Skilled Worker visa",
      "Started his UK career in the renewable energy sector within 3 months of graduation"
    ],
    intro: "Through our specialized approach and Hassan's dedication:"
  }
];

function CaseStudyHassan() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From International Graduate to Sponsored Renewable Energy Consultant in the UK
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyHassan;