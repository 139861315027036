import Navbar from "../features/navbar/Navbar"
import Footer from "../features/footer/Footer"
import { useState } from 'react';
import TopBanner from "../features/banners/TopBanner"
import ContactForm from "../features/contactform/ContactForm"
import CaseStudySaurav from "../features/casestudies/CaseStudySaurav";
import CaseStudyAisha from "../features/casestudies/CaseStudyAisha";
import CaseStudyAli from "../features/casestudies/CaseStudyAli";
import CaseStudyYuki from "../features/casestudies/CaseStudyYuki";
import CaseStudyElena from "../features/casestudies/CaseStudyElena";
import CaseStudyHassan from "../features/casestudies/CaseStudyHassan";
import CaseStudyKareena from "../features/casestudies/CaseStudyKareena";
import CaseStudyPratik from "../features/casestudies/CaseStudyPratik";
import CaseStudyKriteshini from "../features/casestudies/CaseStudyKriteshini";
import CaseStudyHussain from "../features/casestudies/CaseStudyHussain";
  
  export default function BlogPage() {
    const [showContactForm, setShowContactForm] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleContactFormOpen = (action) => {
    setSelectedAction(action);
    setShowContactForm(true);
  };

  const handleContactFormClose = () => {
    setShowContactForm(false);
    setSelectedAction('');
  };
    return (
        <>
        <TopBanner onContactFormOpen={handleContactFormOpen} />
        {showContactForm && (
        <ContactForm
          onClose={handleContactFormClose}
          selectedAction={selectedAction}
        />
      )}
      <div className="">
        <Navbar/>
        </div>
      <CaseStudySaurav/>
      <CaseStudyAisha/>
      <CaseStudyAli/>
      <CaseStudyYuki/>
      <CaseStudyElena/>
      <CaseStudyHassan/>
      <CaseStudyKareena/>
      <CaseStudyPratik/>
      <CaseStudyKriteshini/>
      <CaseStudyHussain/>
        {/* <CaseStudyLalit/> */}
        <Footer/>
      </>
    )
  }
  