import uniLogos from "../../images/unilogos.png"

export default function LogoClouds() {
  return (
    <div class="container p-8 mx-auto w-full max-w-2xl xl:px-0">
      <div class="flex flex-col justify-center">
        <div class="text-xl text-center text-semibold text-gray-700">
          Students from
          <span class="text-indigo-600 font-bold"> 20+</span> different universities
        </div>
        <div class="flex flex-wrap justify-center gap-5 mt-10 md:justify-around">
          <div class="pt-2 text-gray-400">
            <img src={uniLogos} alt="university logos" />
          </div>
        </div>
      </div>
      <div class="text-xl text-center text-semibold text-gray-700 mt-10">
        <span class="text-indigo-600 font-bold"> And many more....</span>
      </div>
    </div>
  );
}